body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-main {
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
}

.pgp {
  font-size: 30px;
  font-weight: 200;
  line-height: 2em;
}

.icon-description {
  font-size: 20px;
  font-weight: 200;
  line-height: 1em;
}

.icon-description:hover { 
  -webkit-transform: scale(1.05); 
          transform: scale(1.05);
  transition-duration: .2s;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
}

.tagline {
  font-size: 1.5rem;
  margin: 1.5rem 0;
  font-weight: 100;
  margin-left: 1.5em;
  margin-right: 1.5em;
}

.intro {
  font-family: 'Reem Kufi', sans-serif;
  font-size: 2.75em;
  font-weight: 600;
  margin-left: 0.5em;
  margin-right: 0.5em;
}

.change-mode {
  position: absolute;
  top: 25px;
  right: 25px;
}

.icons-social i {
  padding: 15px;
  font-size: 2em;
}

@media (min-width: 576px) {
  html {
    font-size: 14px;
  }
}

@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

@media (min-width: 992px) {
  html {
    font-size: 18px;
  }
}

@media (min-width: 1200px) {
  html {
    font-size: 20px;
  }
}

.fa:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
  transition-duration: .2s;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
}

a {
  text-decoration: none;
  color:#ffffff;
}

#span-center { 
  -webkit-justify-content: center; 
          justify-content: center;
}

#div-pgp {
  display: inline;
  padding: 1.5em;
}

/* relevant styles */
.img__wrap {
  position: relative;
}

.img__description_layer {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  color: #fff;
  visibility: hidden;
  opacity: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;

  /* transition effect. not necessary */
  transition: opacity .2s, visibility .2s;
}

.img__wrap:hover .img__description_layer {
  visibility: visible;
  opacity: 1;
}

.img__description {
  transition: .3s;
  -webkit-transform: translateY(1em);
          transform: translateY(1em);
  font-size: 25px;
  font-weight: 100;
  line-height: 2em;
}

.img__wrap:hover .img__description {
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

/* Daylight CSS */

.daylight .App-main {
  background-color: #282c34;
  color: white;
}

.daylight .change-mode:before {
  color: yellow;
  font-family: 'forkawesome';
  font-weight: 400;
  content: '\F185';
}

.daylight .icons-social a {
  color: #fafafa;
}

.daylight .icons-social a svg path {
  fill: #fafafa;
}

/* Nightlight CSS */

.nightlight .App-main {
  background-color: #fafafa;
  color: #000;
}

.nightlight .change-mode:before {
  color: black;
  font-family: 'forkawesome';
  font-weight: 400;
  content: '\F186';
}

.nightlight .icons-social a {
  color: #000;
}

.nightlight .icons-social a svg path {
  fill: #000;
}

/* Custom CSS Start From Here */

/* day CSS */

.day .App-main {
  background-color: #282c34;
  color: white;
}

.day .icons-social a {
  color: #fafafa;
}

.day .icons-social a svg path {
  fill: #fafafa;
}

/* night CSS */

.night .App-main {
  background-color: #fafafa;
  color: #000;
}

.night .icons-social a {
  color: #000;
}

.night .icons-social a svg path {
  fill: #000;
}

/* Dark Colors CSS */

/* terminal CSS */

.terminal .App-main {
  background-color: #4c1130;
  color: #fff;
}

.terminal .icons-social a {
  color: #fff;
}

.terminal .icons-social a svg path {
  fill: #fff;
}

/* torquoise CSS */

.torquoise .App-main {
  background-color: #398677;
  color: #fff;
}

.torquoise .icons-social a {
  color: #fff;
}

.torquoise .icons-social a svg path {
  fill: #fff;
}

/* peterriver CSS */

.peterriver .App-main {
  background-color: #225171;
  color: #fff;
}

.peterriver .icons-social a {
  color: #fff;
}

.peterriver .icons-social a svg path {
  fill: #fff;
}

/* amythyst CSS */

.amythyst .App-main {
  background-color: #9b59b6;
  color: #fff;
}

.amythyst .icons-social a {
  color: #fff;
}

.amythyst .icons-social a svg path {
  fill: #fff;
}

/* carrot CSS */

.carrot .App-main {
  background-color: #976132;
  color: #fff;
}

.carrot .icons-social a {
  color: #fff;
}

.carrot .icons-social a svg path {
  fill: #fff;
}

/* alizarin CSS */

.alizarin .App-main {
  background-color: #72342d;
  color: #fff;
}

.alizarin .icons-social a {
  color: #fff;
}

.alizarin .icons-social a svg path {
  fill: #fff;
}

/* Light Colors CSS */

/* lightred CSS */

.lightred .App-main {
  background-color: #ffcdd2;
  color: #000;
}

.lightred .icons-social a {
  color: #000;
}

.lightred .icons-social a svg path {
  fill: #000;
}

/* lightpurple CSS */

.lightpurple .App-main {
  background-color: #e1bee7;
  color: #000;
}

.lightpurple .icons-social a {
  color: #000;
}

.lightpurple .icons-social a svg path {
  fill: #000;
}

/* lightgreen CSS */

.lightgreen .App-main {
  background-color: #c8e6c9;
  color: #000;
}

.lightgreen .icons-social a {
  color: #000;
}

.lightgreen .icons-social a svg path {
  fill: #000;
}

/* lightblue CSS */

.lightblue .App-main {
  background-color: #b3e5fc;
  color: #000;
}

.lightblue .icons-social a {
  color: #000;
}

.lightblue .icons-social a svg path {
  fill: #000;
}

/* lightyellow CSS */

.lightyellow .App-main {
  background-color: #fff9c4;
  color: #000;
}

.lightyellow .icons-social a {
  color: #000;
}

.lightyellow .icons-social a svg path {
  fill: #000;
}

/* Gradient background */

.gradient {
  width: 100wh;
  height: 100vh;
  color: #fff;
  -webkit-animation: Gradient 15s ease infinite;
  animation: Gradient 15s ease infinite;
}

.gradient .App-main {
  color: #fff;
}

.gradient .icons-social a {
  color: #fff;
}

.gradient .icons-social a svg path {
  fill: #fff;
}

@-webkit-keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

/* Full Page Background Image CSS */

.full-bg-image {
  background-size: cover;
}

.full-bg-image .App-main {
  color: #fff;
}

.full-bg-image .icons-social a {
  color: #fff;
}

.full-bg-image .icons-social a svg path {
  fill: #fff;
}

